import React from 'react'
import Svg from '../../Svg'
import { SvgProps } from '../../types'

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg viewBox="0 0 500 500" {...props}>
            <path d="M250 500C388.071 500 500 388.071 500 250C500 111.929 388.071 0 250 0C111.929 0 0 111.929 0 250C0 388.071 111.929 500 250 500Z" fill="white" />
            <path d="M177.1 316.4C162.2 316.4 150 312.9 140.5 305.9C131.1 298.8 126.4 288.6 126.4 275.5C126.4 272.7 126.7 269.4 127.3 265.4C128.9 256.4 131.2 245.6 134.2 232.9C142.7 198.5 164.7 181.3 200.1 181.3C209.7 181.3 218.4 182.9 226 186.2C233.6 189.3 239.6 194.1 244 200.5C248.4 206.8 250.6 214.3 250.6 223C250.6 225.6 250.3 228.9 249.7 232.9C247.8 244 245.6 254.9 242.9 265.4C238.5 282.5 231 295.4 220.2 303.9C209.5 312.3 195.1 316.4 177.1 316.4ZM179.8 289.4C186.8 289.4 192.7 287.3 197.6 283.2C202.6 279.1 206.2 272.8 208.3 264.2C211.2 252.4 213.4 242.2 214.9 233.4C215.4 230.8 215.7 228.1 215.7 225.3C215.7 213.9 209.8 208.2 197.9 208.2C190.9 208.2 184.9 210.3 179.9 214.4C175 218.5 171.5 224.8 169.4 233.4C167.1 241.8 164.9 252 162.6 264.2C162.1 266.7 161.8 269.3 161.8 272.1C161.7 283.7 167.8 289.4 179.8 289.4Z" fill="black" />
            <path d="M259.3 314.6C257.9 314.6 256.9 314.2 256.1 313.3C255.5 312.3 255.3 311.2 255.5 309.9L281.4 187.9C281.6 186.5 282.3 185.4 283.5 184.5C284.6 183.6 285.8 183.2 287.1 183.2H337C350.9 183.2 362 186.1 370.4 191.8C378.9 197.6 383.2 205.9 383.2 216.8C383.2 219.9 382.8 223.2 382.1 226.6C379 241 372.7 251.6 363.1 258.5C353.7 265.4 340.8 268.8 324.4 268.8H299.1L290.5 309.9C290.2 311.3 289.6 312.4 288.4 313.3C287.3 314.2 286.1 314.6 284.8 314.6H259.3ZM325.7 242.9C331 242.9 335.5 241.5 339.4 238.6C343.4 235.7 346 231.6 347.3 226.2C347.7 224.1 347.9 222.2 347.9 220.6C347.9 217 346.8 214.2 344.7 212.3C342.6 210.3 338.9 209.3 333.8 209.3H311.3L304.2 242.9H325.7Z" fill="black" />
        </Svg>
    )
}

export default Icon
