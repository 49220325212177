import React from 'react'
import Svg from '../../Svg'
import { SvgProps } from '../../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 34 34" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.023 24.7916H8.39594C7.53598 24.7916 7.10598 24.7916 6.84846 24.625C6.57016 24.4451 6.39456 24.1431 6.37597 23.8124C6.35872 23.5064 6.57169 23.1332 6.99763 22.3871L14.9391 8.47445C15.3713 7.7172 15.5875 7.33858 15.8625 7.19763C16.1595 7.04537 16.5118 7.04511 16.8091 7.19696C17.0843 7.33748 17.3009 7.71581 17.7342 8.47239L19.5658 11.6702C19.8809 12.2203 20.0385 12.4955 20.1078 12.7857C20.1832 13.1017 20.1833 13.4308 20.1082 13.7468C20.0392 14.0371 19.8819 14.3124 19.5672 14.8628L14.8184 23.1704C14.4991 23.7289 14.3395 24.0081 14.1208 24.2169C13.8827 24.4441 13.5941 24.6115 13.2785 24.7054C12.9886 24.7916 12.6668 24.7916 12.023 24.7916ZM24.9634 24.7916H20.7582C19.8905 24.7916 19.4565 24.7916 19.1982 24.6236C18.9191 24.4421 18.744 24.1378 18.7275 23.8055C18.7122 23.498 18.9306 23.1233 19.3674 22.3741L21.4665 18.7727C21.8977 18.033 22.1134 17.6631 22.3863 17.5245C22.6814 17.3748 23.0302 17.3746 23.3255 17.5238C23.5987 17.662 23.8148 18.0315 24.247 18.7706L26.3532 22.3721C26.7916 23.1218 27.0108 23.4967 26.9958 23.8044C26.9796 24.137 26.8046 24.4416 26.5254 24.6234C26.2671 24.7916 25.8325 24.7916 24.9634 24.7916Z"
        fill="#FFFFFF"
      />
    </Svg>
  )
}

export default Icon
