import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <g clip-path="url(#clip0_3122_125353)">
        <path
          d="M7.99337 1.33334C4.31337 1.33334 1.33337 4.32001 1.33337 8.00001C1.33337 11.68 4.31337 14.6667 7.99337 14.6667C11.68 14.6667 14.6667 11.68 14.6667 8.00001C14.6667 4.32001 11.68 1.33334 7.99337 1.33334ZM8.00004 13.3333C5.05337 13.3333 2.66671 10.9467 2.66671 8.00001C2.66671 5.05334 5.05337 2.66668 8.00004 2.66668C10.9467 2.66668 13.3334 5.05334 13.3334 8.00001C13.3334 10.9467 10.9467 13.3333 8.00004 13.3333Z"
          fill={props.fill || "#E4A62E"}
        />
        <path
          d="M8.33337 4.66666H7.33337V8.66666L10.8334 10.7667L11.3334 9.94666L8.33337 8.16666V4.66666Z"
          fill={props.fill || "#E4A62E"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3122_125353">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
