import React from 'react'
import Svg from '../../Svg'
import { SvgProps } from '../../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 34 34" {...props}>
      <path
        d="M22.7725 13.1813C22.3543 12.9348 21.8106 12.9348 21.3507 13.1813L18.0886 15.071L15.8721 16.3034L12.6102 18.193C12.1919 18.4395 11.6483 18.4395 11.1883 18.193L8.5954 16.7141C8.17719 16.4676 7.88445 16.0158 7.88445 15.5228V12.6062C7.88445 12.1133 8.13537 11.6614 8.5954 11.4149L11.1464 9.97714C11.5646 9.73066 12.1083 9.73066 12.5683 9.97714L15.1194 11.4149C15.5376 11.6614 15.8303 12.1133 15.8303 12.6062V14.4959L18.0468 13.2224V11.3328C18.0468 10.8398 17.7959 10.3879 17.3359 10.1415L12.6102 7.43022C12.1919 7.18374 11.6483 7.18374 11.1883 7.43022L6.37892 10.1415C5.91889 10.3879 5.66797 10.8398 5.66797 11.3328V16.7963C5.66797 17.2892 5.91889 17.7412 6.37892 17.9876L11.1883 20.6989C11.6065 20.9453 12.1501 20.9453 12.6102 20.6989L15.8721 18.8503L18.0886 17.5768L21.3507 15.7282C21.7688 15.4818 22.3125 15.4818 22.7725 15.7282L25.3235 17.166C25.7417 17.4125 26.0345 17.8644 26.0345 18.3573V21.2739C26.0345 21.7669 25.7836 22.2188 25.3235 22.4652L22.7725 23.9441C22.3543 24.1906 21.8106 24.1906 21.3507 23.9441L18.7996 22.5063C18.3814 22.2599 18.0886 21.808 18.0886 21.315V19.4254L15.8721 20.6989V22.5885C15.8721 23.0814 16.1231 23.5333 16.5831 23.7798L21.3925 26.491C21.8106 26.7375 22.3543 26.7375 22.8143 26.491L27.6237 23.7798C28.0419 23.5333 28.3346 23.0814 28.3346 22.5885V17.1249C28.3346 16.632 28.0837 16.1801 27.6237 15.9337L22.7725 13.1813Z"
        fill="#FFFFFF"
      />
    </Svg>
  )
}

export default Icon
