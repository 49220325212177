import React from 'react'
import styled from 'styled-components'
import Image from './Image'
import loader from '../assets/images/nexa-loader.png'
import { Box } from './Box'

const Wrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../assets/images/MainScreenBg.png) no-repeat center #000000;
  background-size: 100% 100%;
  width: 100vw;
  height: vh;
  max-width: none;
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
  &.hide-loader {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    display:none;
  }
  img {
    position: fixed;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    // transform:translate(-50%, -50%);
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    transition: none !important;
    animation: spin 3s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(180deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
`

const PageLoader: React.FC = () => {

  return (
    
    <Wrapper>
      <Image width={'40px'} height={'40px'} src={loader} />
    </Wrapper>
  )
}

export default PageLoader
