import React from 'react'
import Svg from '../../Svg'
import { SvgProps } from '../../types'

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg viewBox="80 0 470 450" {...props}>
            <path d="M321.883 261.115l44.449 69.762 41.06-23.8-58.375-92.009Z" fill="#FFFFFF" />
            <path d="M444.783 279.1l-.03-19.065-63.785-99.348L357.3 200.838l61.576 99.587 22.264-12.9a9.867 9.867 0 0 0 3.644-7.185Z" fill="#FFFFFF" />
            <path d="M177.492 297.077l31.44 18.116L313.556 147.406l-17.757-.469c-15.129-.214-31.426 3.719-38.907 15.93l-59.413 92.087-19.988 30.711v11.412Z" fill="#FFFFFF" />
            <path d="M373.941 147.406l-46.8.17L221.266 322.294 258.273 343.6l10.064-17.069Z" fill="#FFFFFF" />
            <path d="M464.5 147.165A29.807 29.807 0 0 0 450.513 123.2L334.171 56.3a30.263 30.263 0 0 0-26.693 0c-.972.49-113.141 65.544-113.141 65.544a29.892 29.892 0 0 0-4.454 2.637 29.58 29.58 0 0 0-12.391 22.63V285.666l19.988-30.711-.175-106.913a9.932 9.932 0 0 1 4.113-7.431c.528-.378 114.612-66.414 114.975-66.6a10.122 10.122 0 0 1 8.774-.041L440 140.03a9.882 9.882 0 0 1 4.571 7.871V280.34a9.654 9.654 0 0 1-3.433 7.185l-22.264 12.9-11.487 6.657-41.06 23.8-41.64 24.132a10.092 10.092 0 0 1-7.089-.14l-49.266-28.336L258.272 343.6l44.274 25.491c1.464.832 2.769 1.57 3.839 2.172 1.658.93 2.787 1.551 3.186 1.744a28.769 28.769 0 0 0 11.754 2.418 29.6 29.6 0 0 0 10.84-2.039L453.11 303.345A29.569 29.569 0 0 0 464.5 281.069Z" fill="#FFFFFF" />
        </Svg>
    )
}

export default Icon
