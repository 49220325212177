import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 408.15 362.81" fill="white" {...props}>
      <g>
        <path
          d="M70.97,362.3c-17.83,0-35.66,0.28-53.48-0.09c-14.33-0.29-21.71-12.29-14.98-25c10.67-20.14,22.14-39.86,33.38-59.69
		c49.07-86.58,98.17-173.14,147.31-259.69c2.21-3.9,4.6-7.75,7.34-11.29c6.61-8.55,18.84-8.86,25.33-0.19
		c4.96,6.63,8.98,13.99,13.12,21.19c13.13,22.78,25.98,45.73,39.21,68.45c11.05,18.98,11.06,37.61,0.18,56.74
		c-34.07,59.88-67.93,119.88-101.75,179.9c-8.62,15.3-20.35,26.49-38.23,29.1c-8.35,1.22-16.95,0.87-25.44,0.99
		c-10.66,0.14-21.33,0.03-31.99,0.03C70.97,362.62,70.97,362.46,70.97,362.3z"
        />
        <path
          d="M324.94,362.31c-21.83,0-43.66,0.27-65.48-0.1c-14.41-0.24-21.78-12.29-14.99-24.97c9.49-17.75,19.84-35.04,29.93-52.46
		c10.76-18.58,21.6-37.1,32.5-55.6c1.85-3.14,3.86-6.28,6.29-8.97c6.32-6.98,17.13-7.01,23.46-0.04c2.55,2.81,4.68,6.07,6.61,9.35
		c20.05,34.17,40.02,68.38,59.98,102.6c1.25,2.15,2.42,4.38,3.38,6.67c4.81,11.56-2.17,23.03-14.71,23.38
		c-17.82,0.5-35.65,0.44-53.48,0.6c-4.5,0.04-9,0.01-13.5,0.01C324.94,362.62,324.94,362.46,324.94,362.31z"
        />
      </g>
    </Svg>
  )
}

export default Icon
