import React from 'react'
import Svg from '../../Svg'
import { SvgProps } from '../../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 34 34" {...props}>
      <path
        d="M9.35161 21.4772C9.49031 21.3446 9.67836 21.2701 9.87442 21.2701H27.9641C28.2936 21.2701 28.4586 21.6515 28.2255 21.8744L24.651 25.2926C24.5123 25.4252 24.3243 25.4998 24.1282 25.4998H6.03851C5.709 25.4998 5.54403 25.1184 5.77711 24.8954L9.35161 21.4772Z"
        fill="#FFFFFF"
      />
      <path
        d="M9.35161 8.70691C9.4903 8.57426 9.67835 8.49975 9.87442 8.49976H27.9641C28.2936 8.49976 28.4586 8.88113 28.2255 9.10408L24.651 12.5223C24.5123 12.6549 24.3243 12.7294 24.1282 12.7294H6.03851C5.709 12.7294 5.54403 12.348 5.77711 12.1251L9.35161 8.70691Z"
        fill="#FFFFFF"
      />
      <path
        d="M24.651 15.0514C24.5123 14.9188 24.3243 14.8443 24.1282 14.8443H6.03851C5.709 14.8443 5.54403 15.2256 5.77711 15.4485L9.35161 18.8668C9.4903 18.9994 9.67835 19.0739 9.87442 19.0739H27.9641C28.2936 19.0739 28.4586 18.6926 28.2255 18.4696L24.651 15.0514Z"
        fill="#FFFFFF"
      />
    </Svg>
  )
}

export default Icon
