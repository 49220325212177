import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <path
        d="M27.3354 35.8965V39.931L23.8182 42L20.4044 39.931V35.8965L23.8182 37.9655L27.3354 35.8965ZM8.50781 21.931L11.9216 24V30.931L17.8182 34.4483V38.4827L8.50781 33V21.931ZM39.1285 21.931V33L29.7147 38.4827V34.4483L35.6113 30.931V24L39.1285 21.931ZM29.7147 16.4483L33.2319 18.5172V22.5517L27.3354 26.069V33.1034L23.9216 35.1724L20.5078 33.1034V26.069L14.4044 22.5517V18.5172L17.9216 16.4483L23.8182 19.9655L29.7147 16.4483ZM14.4044 25.4483L17.8182 27.5172V31.5517L14.4044 29.4827V25.4483ZM33.2319 25.4483V29.4827L29.8181 31.5517V27.5172L33.2319 25.4483ZM11.9216 12.931L15.4388 15L11.9216 17.069V21.1034L8.50781 19.0345V15L11.9216 12.931ZM35.7147 12.931L39.2319 15V19.0345L35.7147 21.1034V17.069L32.3009 15L35.7147 12.931ZM23.8182 12.931L27.3354 15L23.8182 17.069L20.4044 15L23.8182 12.931ZM23.8182 6L33.2319 11.4828L29.8181 13.5517L23.9216 10.0345L17.9216 13.5517L14.5078 11.4828L23.8182 6Z"
        fill="#FFFFFF"
      />
    </Svg>
  )
}

export default Icon
